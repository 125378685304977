import { useUI } from '@components/ui'
import { CATEGORY_TAG_PREFIX, PRODUCT_CALLOUT_PREFIX } from '@lib/product'
import { FC } from 'react'

interface props {
  namedTags?: any
  tags?: string[]
  price?: number
  comparePrice?: number
}
type TAG = {
  uid: string
  background_colour: string
  text_colour?: string
  label_text: string
}
const ProductCallouts: FC<props> = ({ namedTags, tags, price, comparePrice }) => {
  const { tags: tagLibraryTags } = useUI()
  const namedTagsTag = namedTags && namedTags.tag
  const giftingInPropsTags = tags && tags.includes('collection:gifting') // Checking if collection:gifting is in the tags prop to avoid duplicating

  const giftingTag =
    !giftingInPropsTags && namedTags?.collection?.includes('gifting') ? `${CATEGORY_TAG_PREFIX}gifting` : null

  const namedTagsTagsArray = Array.isArray(namedTagsTag) ? namedTagsTag : [namedTagsTag].filter(Boolean)
  const productCalloutTags = namedTagsTagsArray.map((tag) => `${PRODUCT_CALLOUT_PREFIX}${tag}`)
  const allTags = [...(giftingTag ? [giftingTag] : []), ...productCalloutTags, ...(tags || [])]

  const matchedTags = tagLibraryTags.filter((tag: { uid: any }) => allTags.includes(tag.uid))
  const firstTwoMatchedTags = matchedTags.slice(0, 2)
  
  return (
    <div className="flex gap-6 absolute top-0 left-0">
      {firstTwoMatchedTags.length > 0 && (
        <>
          {firstTwoMatchedTags.map((tag: TAG, index: number) => {
            return (
              <div
                key={index}
                style={{ backgroundColor: `${tag.background_colour}`, color: `${tag?.text_colour || '#FFFFFF'}` }}
                className="px-16 py-6 title-small uppercase"
              >
                {tag.label_text}
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

export default ProductCallouts
