interface ProductTitleProps {
  title: string
  variant?: string
}

const ProductTitle = ({ title, variant = 'product' }: ProductTitleProps) => {
  const productTitle = title?.split('-')?.slice(1)?.toString()?.trim()?.replace(/,/g, '-')
  if (variant === 'plp') {
    return <h3 className="body capitalize desktop:max-w-394">{productTitle || title}</h3>
  }
  if (variant === 'order') {
    return <h3 className="body-bold capitalize desktop:max-w-394">{productTitle || title}</h3>
  }

  return <h1 className="heading-6 capitalize desktop:max-w-394">{productTitle || title}</h1>
}

export default ProductTitle
