import { Price } from '@components/common'
import DynanmicVideoInView from '@components/common/DynamicVideo/DynamicVideoInView'
import ProductCallouts from '@components/product/ProductCallouts'
import ProductRange from '@components/product/ProductRange'
import { getProductPath } from '@lib/getProductProps'
import { COLOUR_TAG_PREFIX, TYPE_TAG_PREFIX } from '@lib/product'
import cn from 'classnames'
import isbot from 'isbot'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { FC, useEffect, useState } from 'react'
import tailwindConfig from 'tailwind.config.js'
import resolveConfig from 'tailwindcss/resolveConfig'
import { ProductTitle } from '..'
const fullConfig = resolveConfig(tailwindConfig as any)

interface Props {
  title: string
  image?: string
  handle: string
  hoverImage?: string
  price: number
  comparePrice?: number
  namedTags?: any
  tags?: string[]
  range?: string
  mobileSize?: boolean
  handleClick: () => void
  currencyCode: string
  video?: {
    url: string | any
  }
  productType?: string
}

const ProductCard: FC<Props> = ({
  title,
  image,
  hoverImage,
  handle,
  price,
  comparePrice,
  namedTags,
  tags,
  range,
  handleClick,
  mobileSize,
  currencyCode,
  video,
  productType,
}) => {
  const { screens } = fullConfig.theme as Theme
  const breakPointNum = parseInt(screens['tablet'].split('px').join(''))
  const [imageToShow, setImageToShow] = useState(image || '/product-img-placeholder.svg')
  const setHoverImage = () => {
    if (hoverImage) {
      setImageToShow(hoverImage)
    }
  }
  const setMainImage = () => {
    setImageToShow(image || '/product-img-placeholder.svg')
  }

  let colour = tags?.filter((item) => item.startsWith(COLOUR_TAG_PREFIX))?.[0]?.replace(COLOUR_TAG_PREFIX, '') || ''
  let type = tags?.filter((item) => item.startsWith(TYPE_TAG_PREFIX))?.[0]?.replace(TYPE_TAG_PREFIX, '') || ''

  if (namedTags) {
    colour = namedTags?.colour || ''
    type = namedTags?.type || ''
  }
  const [isBot, setIsBot] = useState<boolean>(true)
  useEffect(() => {
    if (typeof window !== undefined) {
      setIsBot(isbot(window.navigator.userAgent))
    }
  }, [])

  const productPath = getProductPath(handle, productType)

  return (
    <div className="w-full" onMouseOver={setHoverImage} onMouseOut={setMainImage}>
      <Link href={productPath} onClick={handleClick} passHref>
        <div className="relative">
          <div>
            {imageToShow &&
              (isBot ? (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                  src={imageToShow}
                  alt="product"
                  width={790}
                  height={790}
                  loading="lazy"
                  sizes="(min-width: 1024px) 448px, 768px"
                />
              ) : (
                <Image
                  src={imageToShow}
                  alt="product"
                  width={790}
                  height={790}
                  layout="responsive"
                  sizes="(min-width: 1024px) 448px, 768px"
                />
              ))}
            {video && (
              <DynanmicVideoInView
                mediaDesktop={video}
                mediaMobile={video}
                breakPoint={breakPointNum}
                settings={{ playsInline: true, loop: true, muted: true }}
                style="absolute top-0"
              />
            )}
          </div>
          <ProductCallouts namedTags={namedTags} tags={tags} price={price} comparePrice={comparePrice} />
        </div>
      </Link>
      {(title || namedTags?.range || range) && (
        <div className={cn('mt-12 items-start', {})}>
          {namedTags?.range || range ? (
            <>
              <div className="flex justify-between">
                <ProductRange
                  tags={tags || []}
                  namedTags={namedTags}
                  range={namedTags?.range || range}
                  textCase="Title"
                  iconHeight={12}
                  iconWidth={12}
                />
                {price > 0 && (
                  <Price
                    price={price}
                    listPrice={comparePrice}
                    prefix={true}
                    currencyCode={currencyCode}
                    className="body"
                  />
                )}
              </div>
              <ProductTitle title={title} variant="plp" />
            </>
          ) : (
            <div className="flex justify-between">
              {title && <ProductTitle title={title} variant="plp" />}
              {price > 0 && (
                <Price
                  price={price}
                  listPrice={comparePrice}
                  prefix={true}
                  currencyCode={currencyCode}
                  className="body"
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ProductCard
